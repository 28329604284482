var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[(_vm.idPerfil==1)?_c('sidebar-item',{attrs:{"link":{
            name: 'Clientes',
            path: '/control-clientes',
            icon: 'ni ni-tv-2 text-primary',
          }}}):_vm._e(),(_vm.idPerfil==1)?_c('sidebar-item',{attrs:{"link":{
              name: 'Administradores',
              path: '/control-administradores',
              icon: 'ni ni-key-25 text-warning'
              }}}):_vm._e(),(_vm.idPerfil==1)?_c('sidebar-item',{attrs:{"link":{
              name: 'Usuarios',
              path: '/control-responsables',
              icon: 'ni ni-circle-08 text-success'
              }}}):_vm._e(),(_vm.idPerfil==1)?_c('sidebar-item',{attrs:{"link":{
              name: 'Cerrar Sesión',
              path: '/login',
              icon: 'ni ni-user-run text-danger'
              }}}):_vm._e(),(_vm.idPerfil == 2)?_c('sidebar-item',{attrs:{"link":{
                    name: 'Capacitaciones',
                    path: '/control-capacitaciones',
                    icon: 'ni ni-tv-2 text-primary'
                }}}):_vm._e(),(_vm.idPerfil==2)?_c('sidebar-item',{attrs:{"link":{
              name: 'Asignar capacitaciones',
              path: '/asignacion-capacitaciones',
              icon: 'ni ni-book-bookmark text-warning'
              }}}):_vm._e(),(_vm.idPerfil==2)?_c('sidebar-item',{attrs:{"link":{
              name: 'Evaluaciones',
              path: '/control-evaluaciones',
              icon: 'ni ni-single-copy-04 text-default'
              }}}):_vm._e(),(_vm.idPerfil==2)?_c('sidebar-item',{attrs:{"link":{
              name: 'Reportes',
              path: '/reportes-estadisticas',
              icon: 'ni ni-chart-pie-35 text-danger'
              }}}):_vm._e(),(_vm.idPerfil==2)?_c('sidebar-item',{attrs:{"link":{
              name: 'Cerrar Sesión',
              path: '/login',
              icon: 'ni ni-user-run text-danger'
              }}}):_vm._e(),(_vm.idPerfil==3)?_c('sidebar-item',{attrs:{"link":{
              name: 'Panel Principal',
              path: '/panel-responsable',
              icon: 'ni ni-tv-2  text-primary'
              }}}):_vm._e(),(_vm.idPerfil==3)?_c('sidebar-item',{attrs:{"link":{
              name: 'Trabajadores',
              path: '/control-trabajador',
              icon: 'ni ni-single-02 text-default'
              }}}):_vm._e(),(_vm.idPerfil==3)?_c('sidebar-item',{attrs:{"link":{
              name: 'Evaluaciones',
              path: '/gestion-evaluacion',
              icon: 'ni ni-single-copy-04 text-warning'
              }}}):_vm._e(),(_vm.idPerfil==3)?_c('sidebar-item',{attrs:{"link":{
              name: 'Cerrar Sesión',
              path: '/login',
              icon: 'ni ni-user-run text-danger'
              }}}):_vm._e(),(_vm.idPerfil==4)?_c('sidebar-item',{attrs:{"link":{
              name: 'Panel Principal',
              path: '/panel-trabajador',
              icon: 'ni ni-tv-2 text-primary'
              }}}):_vm._e(),(_vm.idPerfil==4)?_c('sidebar-item',{attrs:{"link":{
              name: 'Mis evaluaciones',
              path: '/panel-evaluacion',
              icon: 'ni ni-single-copy-04 text-warning'
              }}}):_vm._e(),(_vm.idPerfil==4)?_c('sidebar-item',{attrs:{"link":{
              name: 'Cerrar Sesión',
              path: '/login',
              icon: 'ni ni-user-run text-danger'
              }}}):_vm._e(),(_vm.idPerfil==7)?_c('sidebar-item',{attrs:{"link":{
              name: 'Icons',
              path: '/icons',
              icon: 'ni ni-planet text-blue'
              }}}):_vm._e(),(_vm.idPerfil==7)?_c('sidebar-item',{attrs:{"link":{
                name: 'Maps',
                path: '/maps',
                icon: 'ni ni-pin-3 text-orange'
              }}}):_vm._e(),(_vm.idPerfil==7)?_c('sidebar-item',{attrs:{"link":{
                name: 'User Profile',
                path: '/profile',
                icon: 'ni ni-single-02 text-yellow'
                }}}):_vm._e(),(_vm.idPerfil==7)?_c('sidebar-item',{attrs:{"link":{
                  name: 'Tables',
                  path: '/tables',
                  icon: 'ni ni-bullet-list-67 text-red'
                }}}):_vm._e(),(_vm.idPerfil==7)?_c('sidebar-item',{attrs:{"link":{
                    name: 'Login',
                    path: '/login',
                    icon: 'ni ni-key-25 text-info'
                  }}}):_vm._e(),(_vm.idPerfil==7)?_c('sidebar-item',{attrs:{"link":{
                    name: 'Register',
                    path: '/register',
                    icon: 'ni ni-circle-08 text-pink'
                  }}}):_vm._e()],1)],2),_c('div',{staticClass:"main-content"},[_c('content-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }