<template>
<base-nav container-classes="container-fluid" class="navbar-top navbar-expand" :class="{'navbar-dark': type === 'default'}">

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <!--   <span class="btn-inner--icon" @click="cambiarTam">
        <b-icon style="color:#FFFF ;width: 200%; height: 100%;" icon="list"></b-icon>
    </span>-->
    <span aria-current="page" class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block active router-link-active"> {{$route.name}} </span>
    <!-- Navbar links -->
    <b-navbar-nav class="align-items-center ml-md-auto">
        <!-- This item dont have <b-nav-item> because item have data-action/data-target on tag <a>, wich we cant add -->
        <li class="nav-item d-sm-none">
            <a class="nav-link" href="#" data-action="search-show" data-target="#navbar-search-main">
                <i class="ni ni-zoom-split-in"></i>
            </a>
        </li>
    </b-navbar-nav>
    <b-navbar-nav class="align-items-center ml-auto ml-md-0">
        <base-dropdown menu-on-right class="nav-item" tag="li" title-tag="a" title-classes="nav-link pr-0">
            <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
                <b-media no-body class="align-items-center">
                    <span class="avatar avatar-sm rounded-circle">
                        <img alt="Imagen" src="img/icons/icon_persona.png">
                    </span>
                    <b-media-body class="ml-2 d-none d-lg-block">
                        <span class="mb-0 text-sm  font-weight-bold">{{NombrePersona}}</span>
                    </b-media-body>
                </b-media>
            </a>

            <template>

                <b-dropdown-header class="noti-title">
                    <h6 class="text-overflow m-0">Bienvenido {{DescripcionPerfil}}</h6>
                </b-dropdown-header>
                <b-dropdown-item :to="{name:'mi perfil'}">
                    <i class="ni ni-single-02"></i>
                    <span>Mi perfil</span>
                </b-dropdown-item>
                <div class="dropdown-divider"></div>
                <b-dropdown-item :to="{name:'login'}">
                    <i class=" ni ni-user-run"></i>
                    <span>Salir</span>
                </b-dropdown-item>

            </template>
        </base-dropdown>
    </b-navbar-nav>
</base-nav>
</template>

<script>
import { CollapseTransition} from 'vue2-transitions';
import { BaseNav, Modal } from '@/components';
import CryptoJS from 'crypto-js';

export default {
    components: {
        CollapseTransition,
        BaseNav,
        Modal
    },
    props: {
        type: {
            type: String,
            default: 'default', // default|light
            description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
        }
    },
    computed: {
        routeName() {
            const {
                name
            } = this.$route;
            return this.capitalizeFirstLetter(name);
        }
    },
    data() {
        return {
            activeNotifications: false,
            showMenu: false,
            searchModalVisible: false,
            searchQuery: '',
            perfil: "",
            NombrePersona: "",
            DescripcionPerfil: "",
        };
    },
    methods: {
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        toggleNotificationDropDown() {
            this.activeNotifications = !this.activeNotifications;
        },
        closeDropDown() {
            this.activeNotifications = false;
        },
        cambiarTam() {

        },
        decryptData(encryptedData) {
            const SECRET_KEY = process.env.VUE_APP_SECRET_KEY;
            const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
            return bytes.toString(CryptoJS.enc.Utf8);
        },
    },
    mounted() {
        if (localStorage.usuario) {
            let vDesc = "";
            const decrypt       = this.decryptData(localStorage.usuario);
            const lsUsuario     = JSON.parse(decrypt);  
            this.perfil         = lsUsuario.idPerfil;
            this.NombrePersona  = lsUsuario.sNomUsuario;
            if (this.perfil == 1) { 
                vDesc = "Master";
            } else if (this.perfil == 2) {
                vDesc = "Administrador";
            } else if (this.perfil == 3) {
                vDesc = "Responsable";
            } else {
                vDesc = "Trabajador";
            }
            this.DescripcionPerfil = vDesc;
        }
    }
};
</script>
