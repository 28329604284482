<template>
<div class="wrapper">
    <notifications></notifications>

    <side-bar>
        <template slot="links">

            <sidebar-item v-if="idPerfil==1" :link="{
            name: 'Clientes',
            path: '/control-clientes',
            icon: 'ni ni-tv-2 text-primary',
          }">
            </sidebar-item>
            <sidebar-item v-if="idPerfil==1" :link="{
              name: 'Administradores',
              path: '/control-administradores',
              icon: 'ni ni-key-25 text-warning'
              }">

            </sidebar-item>
            <sidebar-item v-if="idPerfil==1" :link="{
              name: 'Usuarios',
              path: '/control-responsables',
              icon: 'ni ni-circle-08 text-success'
              }">
            </sidebar-item>
            <sidebar-item v-if="idPerfil==1" :link="{
              name: 'Cerrar Sesión',
              path: '/login',
              icon: 'ni ni-user-run text-danger'
              }">
            </sidebar-item>

            <sidebar-item 
                v-if  = "idPerfil == 2" 
                :link = "{
                    name: 'Capacitaciones',
                    path: '/control-capacitaciones',
                    icon: 'ni ni-tv-2 text-primary'
                }"
            >
            </sidebar-item>
            <sidebar-item v-if="idPerfil==2" :link="{
              name: 'Asignar capacitaciones',
              path: '/asignacion-capacitaciones',
              icon: 'ni ni-book-bookmark text-warning'
              }">
            </sidebar-item>
            <sidebar-item v-if="idPerfil==2" :link="{
              name: 'Evaluaciones',
              path: '/control-evaluaciones',
              icon: 'ni ni-single-copy-04 text-default'
              }">
            </sidebar-item>
            <sidebar-item v-if="idPerfil==2" :link="{
              name: 'Reportes',
              path: '/reportes-estadisticas',
              icon: 'ni ni-chart-pie-35 text-danger'
              }">
            </sidebar-item>
            <sidebar-item v-if="idPerfil==2" :link="{
              name: 'Cerrar Sesión',
              path: '/login',
              icon: 'ni ni-user-run text-danger'
              }">
            </sidebar-item>

            <sidebar-item v-if="idPerfil==3" :link="{
              name: 'Panel Principal',
              path: '/panel-responsable',
              icon: 'ni ni-tv-2  text-primary'
              }">
            </sidebar-item>
            <sidebar-item v-if="idPerfil==3" :link="{
              name: 'Trabajadores',
              path: '/control-trabajador',
              icon: 'ni ni-single-02 text-default'
              }">
            </sidebar-item>
            <sidebar-item v-if="idPerfil==3" :link="{
              name: 'Evaluaciones',
              path: '/gestion-evaluacion',
              icon: 'ni ni-single-copy-04 text-warning'
              }">
            </sidebar-item>
            <!--     <sidebar-item v-if="idPerfil==3" :link="{
              name: 'Reportes',
               path: '/reportes-capacitacion',
              icon: 'ni ni-chart-pie-35 text-warning'
              }">
            </sidebar-item>-->
            <sidebar-item v-if="idPerfil==3" :link="{
              name: 'Cerrar Sesión',
              path: '/login',
              icon: 'ni ni-user-run text-danger'
              }">
            </sidebar-item>
            <!-- Clases Grabadas ...Foros....Anuncios....Notas....Cronograma de capacitaciones
            ...evaluaciones.... Pregntas y respuestas..reportes en pdf o excel etcx-->

            <!--panel con desempeños ...ver cursos completados...mis notas..cursos faltantes ...
            timepo en la plataforma...mis cursos asignados...ecaluaciones... preguntas y repuestas-->
            <sidebar-item v-if="idPerfil==4" :link="{
              name: 'Panel Principal',
              path: '/panel-trabajador',
              icon: 'ni ni-tv-2 text-primary'
              }">
            </sidebar-item>
            <sidebar-item v-if="idPerfil==4" :link="{
              name: 'Mis evaluaciones',
              path: '/panel-evaluacion',
              icon: 'ni ni-single-copy-04 text-warning'
              }">
            </sidebar-item>
            <sidebar-item v-if="idPerfil==4" :link="{
              name: 'Cerrar Sesión',
              path: '/login',
              icon: 'ni ni-user-run text-danger'
              }">
            </sidebar-item>
            <!--   
            MultiLevel Example //..
         <sidebar-item v-if="idPerfil==4" :link="{
              name: 'Cerrar Sesión',
              icon: 'ni ni-user-run text-danger'
              }">
                <sidebar-item v-if="idPerfil==4" :link="{
              name: 'Cerrar Sesión',
              path: '/login',
              icon: 'ni ni-user-run text-danger'
              }">
                </sidebar-item>
            </sidebar-item>-->

            <sidebar-item v-if="idPerfil==7" :link="{
              name: 'Icons',
              path: '/icons',
              icon: 'ni ni-planet text-blue'
              }">
            </sidebar-item>

            <sidebar-item v-if="idPerfil==7" :link="{
                name: 'Maps',
                path: '/maps',
                icon: 'ni ni-pin-3 text-orange'
              }">
            </sidebar-item>

            <sidebar-item v-if="idPerfil==7" :link="{
                name: 'User Profile',
                path: '/profile',
                icon: 'ni ni-single-02 text-yellow'
                }">
            </sidebar-item>

            <sidebar-item v-if="idPerfil==7" :link="{
                  name: 'Tables',
                  path: '/tables',
                  icon: 'ni ni-bullet-list-67 text-red'
                }">
            </sidebar-item>

            <sidebar-item v-if="idPerfil==7" :link="{
                    name: 'Login',
                    path: '/login',
                    icon: 'ni ni-key-25 text-info'
                  }">
            </sidebar-item>
            <sidebar-item v-if="idPerfil==7" :link="{
                    name: 'Register',
                    path: '/register',
                    icon: 'ni ni-circle-08 text-pink'
                  }">
            </sidebar-item>
        </template>
    </side-bar>
    <div class="main-content">
        <content-navbar :type="$route.meta.navbarType"></content-navbar>

        <div @click="$sidebar.displaySidebar(false)">

            <fade-transition :duration="200" origin="center top" mode="out-in">
                <!-- your content here -->
                <router-view></router-view>
            </fade-transition>
        </div>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
</div>
</template>

<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
    if (hasElement(className)) {

        new PerfectScrollbar(`.${className}`);
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            initScrollbar(className);
        }, 100);
    }
}

import ContentNavbar from './ContentNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';
import { FadeTransition } from 'vue2-transitions';
import CryptoJS from 'crypto-js';

export default {
    data() {
        return {
            idPerfil: ""
        };
    },
    components: {
        ContentNavbar,
        ContentFooter,
        DashboardContent,
        FadeTransition
    },
    methods: {
        initScrollbar() {
            let isWindows = navigator.platform.startsWith('Win');
            if (isWindows) {
                initScrollbar('sidenav');
            }
        },
        decryptData(encryptedData) {
            try{
                const SECRET_KEY    = process.env.VUE_APP_SECRET_KEY;
                const bytes         = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
                const decrypt       = bytes.toString(CryptoJS.enc.Utf8);
                return decrypt;
            }catch(error){
                console.log(error);
            }
        },
    },
    mounted() {
        if (localStorage.usuario) {
            const decrypt   = this.decryptData(localStorage.usuario);
            const lsUsuario = JSON.parse(decrypt); 
            this.idPerfil   = parseInt(lsUsuario.idPerfil); 
        }
        this.initScrollbar()
    }
};
</script>

<style>
@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 1;
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 1;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.auto-marginx {
    margin: auto;
}

.labelx {
    color: #172b4d;
    font-size: 0.875rem;
    font-weight: 600;
}

@media screen and (max-width: 600px) {
    .movil-img {
        width: 250px;
        padding-bottom: 6px;
    }
}

.pointerx {
    cursor: pointer;
}

.tdx {
    width: 30px;
}

.defaultx {
    background: #32325d;
    color: white
}

.primaryx {
    background: #5e72e4;
    color: white
}

.bg-gradient-defaultx {
    background: linear-gradient(87deg, #f3f2f2 0, #f3f2f2 100%) !important;
}

.table.b-table>tfoot>tr>[aria-sort],
.table.b-table>thead>tr>[aria-sort] {
    cursor: pointer;
    background-image: none;
    background-repeat: no-repeat;
    background-size: .65em 1em;
}

/*-----v-select-----*/


.vs__selected {
    color: #768192 !important;
}

.vs__search,
.vs__search:focus {
    color: #8a93a2 !important;
}

.vs__dropdown-option {
    color: #32325d !important;
}

.vs__dropdown-option:hover {
    background: #32325d !important;
    color: #ffff !important;
}

.vs__clear {
    visibility: hidden;
}

.vs__dropdown-option--highlight {
    background: #32325d !important;
    color: #ffff !important;
}

.style-invalid-select .vs__dropdown-toggle {
    border-color: #e55353 !important;
}

.style-valid-select .vs__dropdown-toggle {
    border-color: #2eb85c !important;
}

.vs__dropdown-toggle {
    border-color: #d8dbe0 !important;
    border: 1px solid;
}

</style>
