import Vue from 'vue';
import CryptoJS from 'crypto-js';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import CONSTANTES from './Constantes.js'
import 'bootstrap-icons/font/bootstrap-icons.css';
import './css/estilos.css'; 

// router setup
import router from './routes/router';
// plugin setup
Vue.use(DashboardPlugin);

import axios from 'axios';
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

Vue.config.productionTip = false
 
function decryptData(encryptedData) {
    try {
        const SECRET_KEY = process.env.VUE_APP_SECRET_KEY;
        const bytes      = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
        const decrypt    = bytes.toString(CryptoJS.enc.Utf8);
        return decrypt;
    } catch (error) {
        console.error('Error al desencriptar:');
    }
}

Vue.mixin({
    methods: {

    },
    mounted() {

    }
});
  
router.beforeEach((to, from, next) => {
    if (to.fullPath !== "/login" && !window.location.href.includes('login') && to.fullPath !== "/Recuperar" && !window.location.href.includes('Recuperar')) {
        if (localStorage.getItem('token') !== '') {
            axios({
                method: 'post',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                url: CONSTANTES.URL_RUTA_SERVICIOS + 'auth/valida-token'
            }).then((response) => { 
                const decrypt   = decryptData(localStorage.usuario); 
                const lsUsuario = JSON.parse(decrypt);
                if (to.meta.isTipo) {
                    if (lsUsuario.idPerfil == to.meta.isTipo) {
                        next();
                    } else {
                        router.push({ name: '404', params: { '0': '' } });
                    }
                } else {
                    next();
                }
            }).catch(function(error) {
                router.push({ name: 'login' });
            });
        } else {
            router.push({ name: 'login' });
        }
    } else {
        next();
    }
});



new Vue({
    router,
    axios,
    el: '#app',
    render: h => h(App),
});