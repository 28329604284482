<template>
<div class="main-content bg-default">
    <base-nav v-model="showMenu" :transparent="true" menu-classes="justify-content-end" class="navbar-horizontal navbar-main navbar-top navbar-dark" expand="lg">
        <div slot="brand" class="navbar-wrapper">
            <b-navbar-brand style="margin-left:30px">
                <img src="img/brand/logo_white_icon.png" class="img-fluid" width="13%" height="auto">
            </b-navbar-brand>
        </div>

    </base-nav>

    <div class="main-content">
        <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
            <router-view></router-view>
        </zoom-center-transition>
    </div>

    <footer class="py-2" id="footer-main">
        <b-container>
            <b-row align-v="center" class="justify-content-xl-between">
                <b-col xl="6">
                    <div class="copyright text-center text-xl-left text-muted">
                        © {{year}} <a href="https://www.im-ra.com" class="font-weight-bold ml-1" target="_blank">IM&RA CORE BUSINESS</a>
                    </div>
                </b-col>
                <b-col xl="6" class="col-xl-6">
            <!--        <b-nav class="nav-footer justify-content-center justify-content-xl-end">
                        <b-nav-item href="https://www.creative-tim.com" target="_blank">
                            Creative Tim
                        </b-nav-item>
                        <b-nav-item href="https://www.creative-tim.com/presentation" target="_blank">
                            About Us
                        </b-nav-item>
                        <b-nav-item href="http://blog.creative-tim.com" target="_blank">
                            Blog
                        </b-nav-item>
                        <b-nav-item href="https://www.creative-tim.com/license" target="_blank">
                            License
                        </b-nav-item>
                    </b-nav>-->
                </b-col>
            </b-row>
        </b-container>
    </footer>
</div>
</template>

<script>
import { BaseNav } from '@/components';
import { ZoomCenterTransition } from 'vue2-transitions';

export default {
    components: {
        BaseNav,
        ZoomCenterTransition
    },
    props: {
        backgroundColor: {
            type: String,
            default: 'black'
        }
    },
    data() {
        return {
            showMenu: false,
            menuTransitionDuration: 250,
            pageTransitionDuration: 200,
            year: new Date().getFullYear(),
            pageClass: 'login-page'
        };
    },
    computed: {
        title() {
            return `${this.$route.name} Page`;
        }
    },
    methods: {
        toggleNavbar() {
            document.body.classList.toggle('nav-open');
            this.showMenu = !this.showMenu;
        },
        closeMenu() {
            document.body.classList.remove('nav-open');
            this.showMenu = false;
        },
        setBackgroundColor() {
            document.body.classList.add('bg-default');
        },
        removeBackgroundColor() {
            document.body.classList.remove('bg-default');
        },
        updateBackground() {
            if (!this.$route.meta.noBodyBackground) {
                this.setBackgroundColor();
            } else {
                this.removeBackgroundColor()
            }
        }
    },
    beforeDestroy() {
        this.removeBackgroundColor();
    },
    beforeRouteUpdate(to, from, next) {
        // Close the mobile menu first then transition to next page
        if (this.showMenu) {
            this.closeMenu();
            setTimeout(() => {
                next();
            }, this.menuTransitionDuration);
        } else {
            next();
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler: function () {
                this.updateBackground()
            }
        }
    },
    mounted() {
       
    }
};
</script>

<style lang="scss" scoped>
$scaleSize: 0.8;

@keyframes zoomIn8 {
    from {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }

    100% {
        opacity: 1;
    }
}

.main-content .zoomIn {
    animation-name: zoomIn8;
}

@keyframes zoomOut8 {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
}

.navbar-horizontal .navbar-brand img {
    height: auto;
}

.main-content .zoomOut {
    animation-name: zoomOut8;
}
</style>

<style>
@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 1;
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 1;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}
</style>
